html, body, #root, .of_community, .main_content, .content_div, .app {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    color: #FFFFFF;
    background: #191D21;
}

p {
    margin: unset;
}

.hash_text {
    max-width: 100px;
    display: flex;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    line-height: 12px;
    color: #B7B7B7;
}

.hash_text .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -1px;
}

.keplr_button {
    margin-top: 20px;
}

.keplr_button button {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #0F0F0F;
    background: #FFFFFF;
    text-transform: unset;
    border-radius: 10px;
    padding: 13px 16px 12px;
}

.keplr_button button:disabled {
    opacity: 0.4;
    color: #0F0F0F;
    background: #FFFFFF;
}

.keplr_button button:hover {
    background: #FFFFFF;
}

.keplr_button button img {
    width: 28px;
    margin-right: 15px;
}

iframe {
    display: none;
}

/* scroll bar */
.scroll_bar {
    overflow-y: auto;
    overflow-x: hidden;
}

html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 6px;
    background: #191D21;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 4px;
    background-clip: content-box;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
}

/*Buttons*/
.app .secondary_button {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    padding: 6px 16px;
    position: relative;
    z-index: 0;
    border-radius: 5px;
    text-transform: inherit;
}

.app .secondary_button > span:first-child {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app .secondary_button:before {
    content: '';
    position: absolute;
    background: #1B1B1B;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 5px;
}

.app .secondary_button:after {
    content: '';
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 5px;
}

/* queue snackbar */
.queue_snackbar {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 99;
}

.queue_snackbar .snackbar {
    position: relative;
    margin: 10px 0 0 0;
}

.queue_snackbar .snackbar:first-child {
    margin-top: 0;
}

.queue_snackbar .snackbar > div {
    position: relative;
}

.queue_snackbar .snackbar p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    font-feature-settings: 'zero' on;
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    margin-left: 40px;
}

.queue_snackbar .snackbar .progress_bar {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    border-radius: 50px;
    background: unset;
}

.queue_snackbar .snackbar .progress_bar > span {
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
}

.queue_snackbar .snackbar .snackbar_class {
    margin-bottom: 6px;
}

.queue_snackbar .snackbar .snackbar_class span {
    text-align: left;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 4px #EB5757;
    }
    25% {
        box-shadow: 0 0 0 1px #EB5757;
    }
    50% {
        box-shadow: 0 0 0 7.5px #EB5757;
    }
    75% {
        box-shadow: 0 0 0 4px #EB5757;
    }
    100% {
        box-shadow: 0 0 0 6px #EB5757;
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate(-3deg);
        transform: scale3d(.9, .9, .9) rotate(-3deg);
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@media (max-width: 425px) {
    .app .secondary_button {
        width: 100%;
        margin-top: 10px;
    }
}
